import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    //redirectTo: 'process',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',    
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'recovery/:token',    
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  }, 
  {
    path: 'company',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule)
  },
  {
    path: 'system',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/system/system.module').then( m => m.SystemPageModule)
  },
  {
    path: 'site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/site/site.module').then( m => m.SitePageModule)
  },
  {
    path: 'sub-site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/sub-site/sub-site.module').then( m => m.SubSitePageModule)
  },
  {
    path: 'system-type',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/system-type/system-type.module').then( m => m.SystemTypePageModule)
  },
  {
    path: 'system-setting/:system_type_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/system-setting/system-setting.module').then( m => m.SystemSettingPageModule)
  },
  {
    path: 'system-command-mapping/:system_type_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/system-command-mapping/system-command-mapping.module').then( m => m.SystemCommandMappingPageModule)
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'log',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/log/log.module').then( m => m.LogPageModule)
  },   
  {
    path: 'mainteneance-reactive',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mainteneance-reactive/mainteneance-reactive.module').then( m => m.MainteneanceReactivePageModule)
  }, 
  {
    path: 'mainteneance-predictive',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mainteneance-predictive/mainteneance-predictive.module').then( m => m.MainteneancePredictivePageModule)
  },  
  {
    path: 'mainteneance-preventive',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mainteneance-preventive/mainteneance-preventive.module').then( m => m.MainteneancePreventivePageModule)
  },
  {
    path: 'mainteneance-log',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mainteneance-log/mainteneance-log.module').then( m => m.MainteneanceLogPageModule)
  },
  {
    path: 'mainteneance-import',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mainteneance-import/mainteneance-import.module').then( m => m.MainteneanceImportPageModule)
  },
  {
    path: 'process',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/process/process.module').then( m => m.ProcessPageModule)
  },
  {
    path: 'work-report',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/work-report/work-report.module').then( m => m.WorkReportPageModule)
  },
  {
    path: 'work-activity',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/work-activity/work-activity.module').then( m => m.WorkActivityPageModule)
  },
  {
    path: 'item',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/item/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'item-movment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/item-movment/item-movment.module').then( m => m.ItemMovmentPageModule)
  },
  {
    path: 'movment-type',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/movment-type/movment-type.module').then( m => m.MovmentTypePageModule)
  },
  {
    path: 'role',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/role/role.module').then( m => m.RolePageModule)
  },
  {
    path: 'movment',
    loadChildren: () => import('./pages/movment/movment.module').then( m => m.MovmentPageModule)
  },
  {
    path: 'work-report-export',
    loadChildren: () => import('./pages/work-report-export/work-report-export.module').then( m => m.WorkReportExportPageModule)
  },
  {
    path: 'mainteneance-import',
    loadChildren: () => import('./pages/mainteneance-import/mainteneance-import.module').then( m => m.MainteneanceImportPageModule)
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
