import { MapsAPILoader } from '@agm/core';
import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { EffectLoadingService } from './services/effect-loading.service';
import { UserRoleTypeEnum } from './class/user.class';
import { NavigationStart, Router } from '@angular/router';


export interface PageMenu {
  title : string | null
  open? : boolean | null
  url : string | null
  icon : string| null
  children? : Array<PageMenu>| null
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public version: string = require('../../package.json').version

  // per l'integrazione delle icon fontawesome4
  // segui https://medium.com/@iamserverr/font-awesome-with-ionic-5-angular-3fcc335cd391

  public appPages: Array<PageMenu> = [];

  public anonymousPages: Array<PageMenu> = [
    { title: 'Login', url: '/login', icon: 'sign-in' }
  ];

  public loggedInPages: Array<PageMenu> = [
    { title: 'Home', open: false, url: '/home', icon: 'home' },   
    { title: 'Dashboard', open: false, url: '/dashboard', icon: 'tachometer' },
    { title: 'Rapporti', open: false, url: '/work-report', icon: 'file-text' },    
    { title: 'Rapporti Export', open: false, url: '/work-report-export', icon: 'file-text' },  
    { title: 'Lavori', open: false, url: '/process', icon: 'users' },
    {
      title: 'Siti', open: false, url: null, icon: 'globe', children: [
        { title: 'Principali', url: '/site', icon: 'map-maker' },
        { title: 'Sub siti', url: '/sub-site', icon: 'compass' }]
    },
    {
      title: 'Dispositivi', open: false, url: null, icon: 'square', children: [
        { title: 'Elenco', url: '/system', icon: 'person' },
        { title: 'Tipi', url: '/system-type', icon: 'person' }]
    },
    {
      title: 'Manutenzioni', open: false, url: null, icon: 'book', children: [
        { title: 'Registro', url: '/mainteneance-log', icon: 'list-all' },
        { title: 'Straordinarie', url: '/mainteneance-reactive', icon: 'exclamation-triangle' },
        { title: 'Ordinarie', url: '/mainteneance-preventive', icon: 'exclamation-circle' },
        { title: 'Predittive', url: '/mainteneance-predictive', icon: 'exclamation' },
        { title: 'Import', url: '/mainteneance-import', icon: 'upload' },
      ]
    },
    { title: 'Logs', open: false, url: '/log', icon: 'database' },
    { title: 'Aziende', open: false, url: '/company', icon: 'industry' },    
    // { title: 'Utenti', open: false, url: '/user', icon: 'users' },
    {
      title: 'Utenti', open: false, url: null, icon: 'users', children: [
        { title: 'lista', url: '/user', icon: 'list-all' },
        { title: 'ruoli', url: '/role', icon: 'list-all' },
     
      ]
    },
    {
      title: 'Articoli', open: false, url: null, icon: 'cubes', children: [
        { title: 'lista', url: '/item', icon: 'list-all' },
        { title: 'matrice', url: '/item/matrix', icon: 'list-all' }             
      ]
    },
    // { title: 'Articoli', open: false, url: '/item', icon: 'cubes' },
    // { title: 'Movimenti', open: false, url: '/item-movment', icon: 'truck' },
    {
      title: 'Movimenti', open: false, url: null, icon: 'cog', children: [
        { title: 'lista', url: '/movment', icon: 'list-all' },
        { title: 'righe', url: '/item-movment', icon: 'list-all' },
        { title: 'tipo movimenti', url: '/movment-type', icon: 'list-all' },
      ]
    },
    {
      title: 'Impostazioni', open: false, url: null, icon: 'cog', children: [
        { title: 'Attività', url: '/work-activity', icon: 'list-all' },
        // { title: 'Tipo movimenti', url: '/movment-type', icon: 'list-all' },
     
      ]
    },
  ];

  routerEventSubscribe: Subscription;
  crPermissionEventSubscribe: Subscription;
  cUserEventSubscribe: Subscription;

  ngOnDestroy(){
    this.routerEventSubscribe.unsubscribe()
    this.crPermissionEventSubscribe.unsubscribe()
    this.cUserEventSubscribe.unsubscribe()
  }

  constructor(
    public authService: AuthService,
    private mapsAPILoader: MapsAPILoader,
    public effectLoadingService :EffectLoadingService,
    private router : Router
  ) {

    this.routerEventSubscribe = this.router.events.subscribe((event)=>{      

      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        // this.authService.updateUserRolePermission()
      }
      
    })

    this.mapsAPILoader.load().then(() => {});

    
    this.crPermissionEventSubscribe = this.authService.currentUserRolePermission.subscribe(userPagePermission => {

      // console.log("this.authService.currentUserRolePermission",userPagePermission, this.authService?.currentUserValue);
      
      //this.appPages = this.anonymousPages;
      // utente non autenticato
      if(!this.authService?.currentUserValue){
        this.appPages = this.anonymousPages;
      } else {

        if(!userPagePermission) return

        // utente loggato

        // se admin
        if(this.authService?.currentUserValue?.role === UserRoleTypeEnum.ADMIN){

          this.appPages = this.loggedInPages;
          return
        }

        // altri utenti
        this.appPages = [];

        for (const page of this.loggedInPages){

          // console.log(page);
          if(page.url){
            // verifico se la pagina di primo livello è attiva
            let targetPage = page.url.toLocaleLowerCase().replace("/","")

            if(userPagePermission.map(p => p.code).includes(targetPage)) {
              // ok
              // console.log("targetPage", page.title);
              // console.log("add page", page);              
              this.appPages.push(page)
            }
            //
          } else {

            let tempChildList = []

            for (const childPage of page.children) {

              let targetChildPage = childPage.url.toLocaleLowerCase().replace("/","")

              if(userPagePermission.map(p => p.code).includes(targetChildPage)) {
                // console.log("add child page", childPage);
                // console.log("targetChildPage    ", targetChildPage);  
                // console.log(this.appPages);               
                //this.appPages[this.appPages.length -1].children.push(childPage)
                tempChildList.push(childPage)
                // this.appPages.push(childPage)
              }

            }// end for child

            // se temp child list è maggiore di 0
            // aggiungo la pagina padre e la lista

            if(tempChildList.length > 0){
              // console.log("targetPage", page.title.toLocaleLowerCase().replace("/",""));
              // console.log("add child page", tempChildList);
              // aggiungo le pagine figlio
              page.children = tempChildList
              // aggiungo la pagina padre
              this.appPages.push(page)
            }


          }


          // console.log('--------------------------');
          
          
          
        }
        // fine
      }



      // if(this.authService?.currentUserValue?.role === UserRoleTypeEnum.ADMIN){

      //   this.appPages = this.loggedInPages;

      // } else {

      //   this.appPages = [];

      //   for (const page of this.loggedInPages){

      //     // console.log(page);
      //     if(page.url){
      //       // verifico se la pagina di primo livello è attiva
      //       let targetPage = page.url.toLocaleLowerCase().replace("/","")

      //       if(userPagePermission.map(p => p.code).includes(targetPage)) {
      //         // ok
      //         // console.log("targetPage", page.title);
      //         // console.log("add page", page);              
      //         this.appPages.push(page)
      //       }
      //       //
      //     } else {

      //       let tempChildList = []

      //       for (const childPage of page.children) {

      //         let targetChildPage = childPage.url.toLocaleLowerCase().replace("/","")

      //         if(userPagePermission.map(p => p.code).includes(targetChildPage)) {
      //           // console.log("add child page", childPage);
      //           // console.log("targetChildPage    ", targetChildPage);  
      //           // console.log(this.appPages);               
      //           //this.appPages[this.appPages.length -1].children.push(childPage)
      //           tempChildList.push(childPage)
      //           // this.appPages.push(childPage)
      //         }

      //       }// end for child

      //       // se temp child list è maggiore di 0
      //       // aggiungo la pagina padre e la lista

      //       if(tempChildList.length > 0){
      //         // console.log("targetPage", page.title.toLocaleLowerCase().replace("/",""));
      //         // console.log("add child page", tempChildList);
      //         // aggiungo le pagine figlio
      //         page.children = tempChildList
      //         // aggiungo la pagina padre
      //         this.appPages.push(page)
      //       }


      //     }


      //     // console.log('--------------------------');
          
          
          
      //   } // end for

      // }




    })


    this.cUserEventSubscribe = this.authService.currentUser.subscribe(data => {
      //console.log("currentUser.subscribe", data);

      if(!data) return

      if(data.role === UserRoleTypeEnum.ADMIN){

        this.authService.updateUserRolePermission()

      }
      

      let userPagePermission = this.authService.currentUserRolePermissionValue;

      // console.log("userPagePermission", userPagePermission);
      // console.log('app component', data);
      // console.log("userPagePermission", userPagePermission);
      


      // if (data) { 
      //   // user logged in

      //   if(data.role === UserRoleTypeEnum.ADMIN){

      //     this.appPages = this.loggedInPages;

      //   } else {

      //     this.appPages = [];

      //     for (const page of this.loggedInPages){

      //       // console.log(page);
      //       if(page.url){
      //         // verifico se la pagina di primo livello è attiva
      //         let targetPage = page.url.toLocaleLowerCase().replace("/","")
  
      //         if(userPagePermission.map(p => p.code).includes(targetPage)) {
      //           // ok
      //           // console.log("targetPage", page.title);
      //           // console.log("add page", page);              
      //           this.appPages.push(page)
      //         }
      //         //
      //       } else {
  
      //         let tempChildList = []
  
      //         for (const childPage of page.children) {
  
      //           let targetChildPage = childPage.url.toLocaleLowerCase().replace("/","")
  
      //           if(userPagePermission.map(p => p.code).includes(targetChildPage)) {
      //             // console.log("add child page", childPage);
      //             // console.log("targetChildPage    ", targetChildPage);  
      //             // console.log(this.appPages);               
      //             //this.appPages[this.appPages.length -1].children.push(childPage)
      //             tempChildList.push(childPage)
      //             // this.appPages.push(childPage)
      //           }
  
      //         }// end for child
  
      //         // se temp child list è maggiore di 0
      //         // aggiungo la pagina padre e la lista
  
      //         if(tempChildList.length > 0){
      //           // console.log("targetPage", page.title.toLocaleLowerCase().replace("/",""));
      //           // console.log("add child page", tempChildList);
      //           // aggiungo le pagine figlio
      //           page.children = tempChildList
      //           // aggiungo la pagina padre
      //           this.appPages.push(page)
      //         }
  
  
      //       }
  
  
      //       // console.log('--------------------------');
            
            
            
      //     } // end for

      //   }



        


          
        
      //   // this.appPages = this.loggedInPages;
      // } else {
      //   // anonymous user
      //   this.appPages = this.anonymousPages;
      // }



    })

  }


}
